<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->

      <el-form ref="form" label-width="80px" size="mini">
        <div class="flexparents">
          开始日期:
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          结束日期:
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>
          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="loadArticles(1)"
            >查询</el-button
          >

          <!-- <el-button type="success" :disabled="loading" @click="exportexcel()"
            >导出</el-button
          > -->

          <el-button type="info" :disabled="loading" @click="clearAndSearch()"
            >清空</el-button
          >
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>

      <el-table
        :data="articles"
        stripe
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column prop="orderNo" width="180" label="工单号">
        </el-table-column>
        <el-table-column prop="clientName" label="客户"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>

        <el-table-column
          prop="sourceTypeName"
          label="信息来源"
        ></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>

        <el-table-column label="类型" key="type">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="optiontype[scope.row.type].type">{{
              optiontype[scope.row.type].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          prop="description"
          width="300"
          label="问题描述"
        ></el-table-column>

        <el-table-column width="50" label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="addplan(scope.row)"
                  circle
                  icon="el-icon-tickets"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>

    <el-dialog :visible.sync="showdatile">
      <el-descriptions
        title="内容详情:"
        direction="vertical"
        :column="4"
        border
      >
        <el-descriptions-item label="客服单号" :span="4">{{
          dataildata.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="客户姓名" :span="4">{{
          dataildata.clientName
        }}</el-descriptions-item>
        <el-descriptions-item label="标题" :span="4">{{
          dataildata.title
        }}</el-descriptions-item>
        <el-descriptions-item label="问题描述" :span="4">{{
          dataildata.description
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>
<script>
import { getopinionlist, getopiniondatile } from "@/api/options";

export default {
  name: "",
  props: {},
  data() {
    return {
      articles: [], // 文章数据列表
      form: {},
      optiontype: [
        { status: 0, text: "投诉", type: "danger" }, // 1
        { status: 1, text: "意见", type: "warning" }, // 1
        { status: 2, text: "建议", type: "info" }, // 1
      ],
      dataildata: {},
      showdatile: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.loadArticles(1);
  },
  mounted() {},
  methods: {
    async loadArticles(page = 1) {
      // 展示加载中 loading

      try {
        let res;

        res = await getopinionlist(this.form);

        if (res.data.code === 200) {
          this.articles = res.data.data.record;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },

    cleardata() {
      this.form = {};
      this.loadArticles();
    },
    async addplan(row) {
      try {
        let res;

        res = await getopiniondatile({ workOrderId: row.workOrderId });

        if (res.data.code === 200) {
          this.dataildata = res.data.data;
          this.showdatile = true;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    onCurrentChange(page) {
      this.loadArticles(page);
    },
  },
};
</script>

<style scoped lang="less">
.filter-card {
  margin-bottom: 30px;
}

.list-table {
  margin-bottom: 20px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.flexparents {
  margin-bottom: 10px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}
/deep/.el-descriptions-item__label {
  background: #77d5f4;
  font-weight: bolder;
  color: black;
}
.zdhh {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
