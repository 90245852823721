import request from "../utils/requst"

export const getopinionlist = params =>{
    return request({
        method:'GET',
        url:'procedure/wx-applet-opinion/page',
        params,
    })
}

export const getopiniondatile = params =>{
    return request({
        method:'GET',
        url:'procedure/wx-applet-opinion/get',
        params,
    })
}

export const BackToFae = data => {
    return request({
        method: 'POST',
        url: 'procedure/technical-support-work-order/development_work_order/reopen',
        data,
    })
}


